<template lang="pug">
  v-container#user-profile(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Retenciones
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-row(justify='center')
          v-col(cols='12')
            v-form(ref="formRetenciones" v-model="valid" :lazy-validation="lazy" style="width:100%; min-width:200px;")
              v-container.py-0
                v-row
                  //- Código de retención
                  v-col(cols="12" md='4')
                    v-text-field(:rules="[rules.required]" v-model="retencion.codigo" hint="Ingrese el código" ref="codigo" type="text" @keypress="isNumber($event)" @keydown="validar($event, retencion.codigo, 5)" autocomplete="off" class="inputNumber")
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Código' }}
                  v-col(cols="12" md='4')
                    v-text-field(:rules='[rules.required]' v-model="retencion.porcentaje" hint="Ingrese el porcentaje" ref="porcentaje" type="text" @keypress="isNumber($event)" @keydown="validarDec($event, retencion.porcentaje, 7)" autocomplete="off" class="inputNumber")
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Porcentaje' }}
                  v-col(cols="12" md='4')
                    v-select(:disabled="retencion.id" :rules='[rules.required]' return-object v-model="retencion.tipo" :items='tipoRetencionLst' item-text='nombre' hide-details='' single-line='')
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Tipo de retención' }}
                  v-col(cols='12' md='12')
                    v-text-field(:rules='[rules.required]' v-model="retencion.nombre")
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Nombre' }}
                  v-col(cols="12" md="4" offset-md="8")
                    v-btn.mr-4(block color="primary" @click="saveItem" :loading="loadingSave")
                      | GUARDAR DATOS
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'

  export default {
    props: {
      retencionToEdit: {
        default: function () {
          return {
            codigo: '',
            nombre: '',
            porcentaje: '',
            tipo: null,
            completedata: '',
            filterdata: '',
            retencionKey: '',
          }
        },
        type: Object,
      },
    },
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      lazy: false,
      valid: true,
      loadingSave: false,
      rules: {
        required: value => !!value || 'Requerido',
      },
      tipoRetencionLst: [
        { codigo: '001', nombre: 'Retención al IVA' },
        { codigo: '002', nombre: 'Retención al IR' },
      ],
      retencion: {},
    }),
    computed: {
      retencionLst () {
        return this.$store.state.ivaretenciones.retencionesLst
      },
    },
    watch: {
      retencionToEdit () {
        this.retencion = this.retencionToEdit
      },
    },
    created () {
      this.retencion = this.retencionToEdit
    },
    methods: {
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      validarDec ($event, message, longitud) {
        const key = $event.keyCode ? $event.keyCode : $event.which
        if (key !== 8 && key !== 37 && key !== 39 && key !== 46 && key !== 35 && key !== 36 && key !== 9) {
          // Ingresa solo numeros y puntos.
          if ((!/\d/.test($event.key) && $event.key !== '.') || message.length >= longitud) {
            return $event.preventDefault()
          }
          if (!/\d/.test($event.key) && /\./.test(message)) {
            return $event.preventDefault()
          }
          if ($event.key === '.' && message === '') {
            return $event.preventDefault()
          }
          // Solo se puede ingresar dos decimales
          if (/\.\d{2}/.test(message)) return $event.preventDefault()
        }
      },
      validar ($event, value, longitud) {
        const key = $event.keyCode ? $event.keyCode : $event.which
        if (key !== 8 && key !== 37 && key !== 39 && key !== 46 && key !== 35 && key !== 36 && key !== 9) {
          if (!/\d/.test($event.key) || value.length >= longitud) {
            return $event.preventDefault()
          }
        }
      },
      saveItem () {
        if (this.$refs.formRetenciones.validate()) {
          if (this.retencion.retencionKey === '' || !this.retencion.retencionKey) {
            const filterItemCodigo = this.retencionLst.filter(item => {
              return item.codigo === this.retencion.codigo
            })
            if (filterItemCodigo.length > 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Código de retencion ya agregado.',
              }
              return
            }
            const filterItemRet = this.retencionLst.filter(item => {
              return (item.tipo.codigo === this.retencion.tipo.codigo && item.porcentaje === this.retencion.porcentaje)
            })
            if (filterItemRet.length > 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Tipo de retención y porcentaje ya agregado.',
              }
              return
            }
            this.retencion.retencionKey = firebase.database().ref(`ivaretenciones/${this.$store.state.user.user.uid}`).push().getKey()
          }
          this.loadingSave = true
          this.retencion.completedata = this.retencion.codigo + ' ' + this.retencion.nombre + ' ' + this.retencion.porcentaje + '%'
          this.retencion.filterdata = this.retencion.codigo + '_' + this.retencion.porcentaje
          this.$store.dispatch('ivaretenciones/guardarRetenciones', [this.retencion.retencionKey, this.retencion]).then(async resolve => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Retención registrada correctamente',
            }
            this.loadingSave = false
            this.$emit('closeModal')
          }, reject => {
            this.loadingSave = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al registrar la retención',
            }
          })
        }
      },
    },
  }
</script>
